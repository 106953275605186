import PhoneInput from "react-phone-number-input";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import profile from '../assets/icons/profile.png';
import email from '../assets/icons/message.png';
import Select from "react-select";
import {list} from "../data/emergency";

export default function CorrespondentBlock({number, index, setContacts, contacts}: {
    number: number,
    index: number,
    setContacts: any,
    contacts: any
}) {

    const {t} = useTranslation();
    const [isDisabled, setIsDisabled] = useState(false)
    const setNumber = (value: any) => {
        let tmp = [...contacts];
        tmp[index].number = value;
        setContacts(tmp);
        if (typeof value !== 'undefined') {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }

    const removeBlock = () => {
        if (contacts.length === 1) {
            return;
        }

        let tmp = [...contacts];
        tmp.splice(index, 1);
        setContacts(tmp);
    }

    const handleEmergencyNumber = (data: any) => {
        if (data !== null) {
            let tmp = [...contacts];
            tmp[index].number = data.value;
            setContacts(tmp);
        }
    }

    const emergencyList = list()

    useEffect(() => {
        if (contacts[index].number !== '') {
            setIsDisabled(true)
        }
    }, []);

    return (
        <div className='position-relative mb-5'>
            <div className='position-absolute top-0 end-0 pe-2'>
                <i
                    className="fa-solid fa-circle-xmark text-danger fs-2 pointer-event"
                    onClick={removeBlock}
                ></i>
            </div>
            <h5>Contact Nº{number}</h5>
            {number === 1 && <div>
                <div className='text-center mt-2 mb-2'>
                    Un service national d’’urgence par appel téléphonique uniquement 3 clics<br/>
                    <small><i>Pas d'email d'information</i></small>
                </div>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={isDisabled}
                    isClearable={true}
                    isSearchable={true}
                    options={emergencyList}
                    onChange={handleEmergencyNumber}
                />
                <div className='fw-bold mt-3 mb-3'><i>Ou choisi parmi les proches</i></div>
            </div>}
            <select className="form-select mb-4"
                    onChange={(event => {
                        let tmp = [...contacts];
                        tmp[index].relation = event.target.value;
                        setContacts(tmp);
                    })}
            >
                <option value='' selected={contacts[index].relation === ''}>Relation</option>
                <option value="Conjoint" selected={contacts[index].relation === 'Conjoint'}>Conjoint(e)</option>
                <option value="Fils" selected={contacts[index].relation === 'Fils'}>Fils</option>
                <option value="Fille" selected={contacts[index].relation === 'Fille'}>Fille</option>
                <option value="Père" selected={contacts[index].relation === 'Père'}>Père</option>
                <option value="Mère" selected={contacts[index].relation === 'Mère'}>Mère</option>
                <option value="Grand-père" selected={contacts[index].relation === 'Grand-père'}>Grand-père</option>
                <option value="Grand-mère" selected={contacts[index].relation === 'Grand-mère'}>Grand-mère</option>
                <option value="Ami(e)" selected={contacts[index].relation === 'Ami(e)'}>Ami(e)</option>
                <option value="Collègue de travail" selected={contacts[index].relation === 'Collègue de travail'}>Collègue de travail</option>
            </select>
            <div className='input-group'>
                <span
                    className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0 mb-4"
                    id="basic-addon1">
                    <img src={profile} style={
                        {
                            width: '15px'
                        }
                    }/>
                </span>
                <input placeholder={t('form-elements.last_first')}
                       type='text'
                       className='form-control border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0 mb-4'
                       value={contacts[index].name}
                       onChange={(event => {
                           let tmp = [...contacts];
                           tmp[index].name = event.target.value;
                           setContacts(tmp);
                       })}
                />
            </div>
            <div className='input-group'>
                <span
                    className="input-group-text mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                    id="basic-addon1">
                            <img src={email} style={
                                {
                                    width: '15px'
                                }
                            }/>
                        </span>
                <input placeholder='email'
                       type='email'
                       className='form-control mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'
                       value={contacts[index].email}
                       onChange={(event => {
                           let tmp = [...contacts];
                           tmp[index].email = event.target.value;
                           setContacts(tmp);
                       })}
                />
            </div>
            <PhoneInput
                international={false}
                defaultCountry="FR"
                countries={["FR"]}
                country="FR"
                className='form-control border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'
                placeholder={t('form-elements.number')}
                value={contacts[index].number}
                onChange={value => setNumber(value)}
            />

        </div>
    )
}