import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import one from "../assets/bg/1.jpg";
import two from "../assets/bg/2.jpg";
import three from "../assets/bg/3.jpg";
import four from "../assets/bg/4.jpg";
import black from "../assets/bg/black.jpg";
import white from "../assets/bg/white.jpg";
import logo from "../assets/picto.png";
import check from "../assets/icons/check.png";

const ChooseBackground = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [user] = useState(JSON.parse(localStorage.getItem('user') || '[]'))
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [preview, setPreview] = useState(false)
    const [background, setBackground] = useState(localStorage.getItem('background') || 'null')
    const [opacity, setOpacity] = useState(localStorage.getItem('opacity') || '1')

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleSetBG = () => {
        if (background == 'null') {
            localStorage.removeItem('background');
            navigate('/');
            return;
        }

        localStorage.setItem('background', background);
        navigate('/');
    }

    useEffect(() => {
        const first = localStorage.getItem('first_launch');
        if (first === null) {
            navigate('/install');
            return;
        }
    }, [])

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        if (!file.type.startsWith("image/")) {
            alert("Veuillez choisir une image.");
            return;
        }

        const maxSizeMB = 1 * 1024 * 1024;
        if (file.size > maxSizeMB) {
            alert("Le fichier doit peser moins de 1MB.");
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result as string;
            setBackground(base64String);
            setPreview(true)
        };
        reader.readAsDataURL(file);
    };

    const setTransparency = (e: any) => {
        const target = e.target as HTMLInputElement;
        setOpacity(target.value)
        localStorage.setItem('opacity', target.value)
    }

    return (
        <div className='container ms-auto me-auto h-100 ps-0 pe-0'>
            {!preview && <div className='ps-5 pe-5'>
                <h3 className='mt-5 roboto-bold dark-blue ms-3'>Choix fond d'écran</h3>
                <div className='text-center position-relative mb-5 ps-3 pe-3'>
                    <div className='text-center mb-2'>
                        <button
                            type="button"
                            className="btn d-flex align-items-center justify-content-between w-100 px-3 py-2"
                            style={{
                                borderRadius: '10px',
                                backgroundColor: '#eef2fe',
                                color: '#567df4',
                            }}
                            onClick={handleButtonClick}
                        >
                            <span>Importer image personnelle</span>
                            <i className="fas fa-upload"></i>
                        </button>
                        <input className="d-none" type="file" accept="image/*" ref={fileInputRef}
                               onChange={handleFileUpload}/>
                    </div>
                    <div className='row'>
                        <div className='col-6 mb-2 position-relative'>
                            <div className='position-absolute' style={{
                                bottom: '10px',
                                right: '50px'
                            }}>
                                <input type='radio' name='screen' className='custom-radio' value='null'
                                       onChange={() => {
                                           setBackground('null')
                                           setPreview(true)
                                       }}
                                       onClick={() => {
                                           setBackground('null')
                                           setPreview(true)
                                       }}
                                       checked={background == 'null'}/>
                            </div>
                            <img
                                src={black}
                                className="img-fluid mt-5 image-radius"
                                style={{
                                    //width: '75%',
                                    height: "250px"
                                }} alt=''/>
                        </div>
                        <div className='col-6 mb-2 position-relative'>
                            <div className='position-absolute' style={{
                                bottom: '10px',
                                right: '50px'
                            }}>
                                <input type='radio' name='screen' className='custom-radio' value='blank'
                                       onChange={() => {
                                           setBackground('blank')
                                           setPreview(true)
                                       }}
                                       onClick={() => {
                                           setBackground('blank')
                                           setPreview(true)
                                       }}
                                       checked={background == 'blank'}/>
                            </div>
                            <img
                                src={white}
                                className="img-fluid mt-5 image-radius border border-dark"
                                style={{
                                    //width: '75%',
                                    height: "250px"
                                }} alt=''/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 position-relative'>
                            <div className='position-absolute' style={{
                                bottom: '10px',
                                right: '50px'
                            }}>
                                <input
                                    type='radio'
                                    name='screen'
                                    className='custom-radio'
                                    value={one}
                                    onChange={() => {
                                        setBackground(one)
                                        setPreview(true)
                                    }}
                                    onClick={() => {
                                        setBackground(one)
                                        setPreview(true)
                                    }}
                                    checked={background === one}/>
                            </div>
                            <img
                                src={one}
                                className="img-fluid mt-5 image-radius"
                                style={{
                                    //width: '90%',
                                    height: "250px"
                                }} alt=''/>

                        </div>
                        <div className='col-6 position-relative'>
                            <div className='position-absolute' style={{
                                bottom: '10px',
                                right: '50px'
                            }}>
                                <input
                                    type='radio'
                                    name='screen'
                                    className='custom-radio'
                                    value={two}
                                    onChange={() => {
                                        setBackground(two)
                                        setPreview(true)
                                    }}
                                    onClick={() => {
                                        setBackground(two)
                                        setPreview(true)
                                    }}
                                    checked={background === two}/>
                            </div>
                            <img
                                src={two}
                                className="img-fluid mt-5 image-radius"
                                style={{
                                    //width: '75%',
                                    height: "250px"
                                }} alt=''/>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 position-relative'>
                            <div className='position-absolute' style={{
                                bottom: '60px',
                                right: '50px'
                            }}>
                                <input
                                    type='radio'
                                    name='screen'
                                    className='custom-radio'
                                    value={three}
                                    onChange={() => {
                                        setBackground(three)
                                        setPreview(true)
                                    }}
                                    onClick={() => {
                                        setBackground(three)
                                        setPreview(true)
                                    }}
                                    checked={background === three}/>
                            </div>
                            <img
                                src={three}
                                className="img-fluid mt-5 image-radius"
                                style={{
                                    //width: '75%'
                                }} alt=''/>

                        </div>
                        <div className='col-6 mb-5 position-relative'>
                            <div className='position-absolute' style={{
                                bottom: '10px',
                                right: '50px'
                            }}>
                                <input
                                    type='radio'
                                    name='screen'
                                    className='custom-radio'
                                    value={four}
                                    onChange={() => {
                                        setBackground(four)
                                        setPreview(true)
                                    }}
                                    onClick={() => {
                                        setBackground(four)
                                        setPreview(true)
                                    }}
                                    checked={background === four}/>
                            </div>
                            <img
                                src={four}
                                className="img-fluid mt-5 image-radius"
                                style={{
                                    //width: '75%'
                                }} alt=''/>

                        </div>
                    </div>
                    <div className='fixed-bottom bottom-0 bg-white text-center px-5 pt-3' style={
                        {
                            height: '75px'
                        }
                    }>
                        <Link to='/' className='btn btn-outline-dark outline-grey shadow-lg w-100'>Fermer</Link>
                    </div>
                </div>
            </div>}

            {preview &&
                <div style={background != 'null' ? {
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover'
                } : {
                    background: 'black'
                }} className="container col-12 col-md-4 ms-auto me-auto ps-0 pe-0 pt-5">
                    <div className="d-flex justify-content-center mt-5 pt-5">
                        <img
                            src={logo}
                            className="img-fluid mt-5"
                            style={background != 'null' ? {
                                width: '80%',
                                opacity: `${opacity}`
                            } : {
                                width: '75%',
                                opacity: `${opacity}`
                            }} alt=''/>
                    </div>
                    <div className='position-absolute bottom-0 mb-5' style={{
                        left: '15%'
                    }}>
                        <div className='ms-5 mb-5' style={{
                            width: '250px'
                        }}>
                            <label htmlFor="customRange1"
                                   className="form-label roboto-regular text-blue">Transparence</label>
                            <input type="range" onChange={event => setTransparency(event)}
                                   className="form-range slider"
                                   min="0"
                                   max="1"
                                   defaultValue={opacity}
                                   step='0.1' id="customRange1"/>
                        </div>
                        <button className='btn bg-light opacity-75 py-3 px-5 me-5 ms-5' style={{
                            width: '250px'
                        }}
                                onClick={handleSetBG}
                        >Appliquer
                        </button>
                        <button className='btn bg-danger opacity-75 py-3 px-5 me-5 ms-5 mt-2' style={{
                            width: '250px'
                        }}
                                onClick={() => setPreview(false)}
                        >Annuler
                        </button>
                    </div>
                </div>
            }
            <style>
                {`
                .custom-radio:checked {
                    background: url(${check}) no-repeat center center;
                    background-size: contain;
                    border: none;
                }
                `}
            </style>
        </div>
    )
}

export default ChooseBackground;