import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-phone-number-input/style.css'
import App from './App';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';

function checkForUpdates() {
    fetch(window.location.href)
        .then((response) => {
            const currentVersion = response.headers.get('ETag') || response.headers.get('Last-Modified') || ''; // Fallback to an empty string
            const savedVersion = localStorage.getItem('appVersion') || ''; // Fallback to an empty string

            if (!savedVersion) {
                localStorage.setItem('appVersion', currentVersion);
            } else if (savedVersion !== currentVersion) {
                // If a new version is detected, reload the page
                localStorage.setItem('appVersion', currentVersion);
                window.location.reload(); // Force reload from server
            }
        })
        .catch((err) => console.log('Error checking for updates:', err));
}

// Check for updates on app load
checkForUpdates();

// Set interval to check for updates every 10 minutes (600000 milliseconds)
setInterval(checkForUpdates, 600000); // 10 minutes

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
      <ToastContainer/>
        <App/>
    </React.StrictMode>
);

