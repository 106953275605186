import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {t} from "i18next";

export default function About() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [user] = useState(JSON.parse(localStorage.getItem('user') || '[]'))

    useEffect(() => {
        const first = localStorage.getItem('first_launch');
        if (first === null) {
            navigate('/install');
            return;
        }

    }, [])

    return (
        <div className='container col-12 col-md-4 ms-auto me-auto ps-5 pe-5'>
            <div className='mt-5 roboto-bold ms-3 dark-blue'>
                <h4>{t('messages.about')}</h4>
            </div>
            <div className='p-3'>
                <p>
                    Urgent Assistance © {new Date().getFullYear()}<br/>
                    {t('messages.distributed')}
                </p>
                <p>{t('messages.reserved')}</p>
                <p>
                    {t('messages.licensed')}<br/>
                    {user.name}<br/>
                    {t('messages.at')} {user.number}
                </p>
                <p>
                    {t('messages.advert')} :
                </p>
                <p style={{
                    textAlign: 'justify'
                }}>
                    {t('messages.about1')}
                </p>
                <p style={{
                    textAlign: 'justify'
                }}>
                    {t('messages.about2')}
                </p>
            </div>
            <div className='fixed-bottom bottom-0 bg-white text-center px-5 pt-3' style={
                {
                    height: '75px'
                }
            }>
                <Link to='/' className="btn btn-outline-dark outline-grey shadow-lg w-100">{t('buttons.close')}</Link>
            </div>

        </div>
    )
}