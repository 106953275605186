import React, {useEffect, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import {useGeolocated} from "react-geolocated";
import 'leaflet/dist/leaflet.css';
import icon from '../assets/icons/location.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L, {LatLng, LatLngExpression} from 'leaflet';
import {Link} from "react-router-dom";
import {t} from "i18next";

// Set up the default icon for markers
const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;
const ActualLocation = () => {
    const [position, setPosition] = useState<[number, number]>([48.8566, 2.3522]);
    const SetViewOnPositionChange = ({ position } :any) => {
        const map = useMap();
        useEffect(() => {
            if (position) {
                map.setView(position, 13);
            }
        }, [map, position]);
        return null;
    };

    const {
        coords,
        isGeolocationAvailable,
        isGeolocationEnabled,
    } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: 15000,
    });

    const share = async () => {
        const shareData = {
            title: "URGENT Assistance",
            text: "Ma localisation",
            url: `https://www.google.com/maps?q=${coords?.latitude},${coords?.longitude}`,
        };

        try {
            await navigator.share(shareData);

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        console.log('Coords:', coords); // Log the coordinates for debugging
        if (coords) {
            setPosition([coords.latitude, coords.longitude]);
        }
    }, [coords]);

    if (!isGeolocationAvailable) {
        return <div>Votre navigateur ne supporte pas la geolocalisation</div>;
    }

    if (!isGeolocationEnabled) {
        return <div>Veuillez activer la géolocalisation</div>;
    }

    if (!coords) {
        return <div>Chargement en cours...</div>;
    }

    return (
        <div className='container col-12 col-md-4 ms-auto me-auto h-100'>
            <div className='row text-center position-absolute bottom-0 bg-dark-blue w-100 pb-3' style={{
                zIndex: '999'
            }}>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-6'>
                            <h3 className='mt-3 text-center text-white'>Je suis là</h3>
                        </div>
                        <div className='col-6'>
                        <i
                            className="fa-solid fa-share-nodes mt-2 text-white"
                            style={{
                                fontSize: '50px'
                            }}
                            onClick={share}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <Link to='/' className="btn outline-grey shadow-lg w-100 text-white mt-2">{t('buttons.close')}</Link>
                </div>
            </div>
            <MapContainer zoom={13} scrollWheelZoom={false} style={{height: '100vh'}}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                    <Popup>
                        Je suis là
                    </Popup>
                </Marker>
                <SetViewOnPositionChange position={position}/>
            </MapContainer>
            <div className="text-center mt-5">
                <Link to='/' className="btn bg-black text-white">{t('buttons.close')}</Link>
            </div>
        </div>
    )
}

export default ActualLocation;