const list = () => {
    const data = {
        "Afghanistan": "112",
        "Albanie": "112",
        "Algérie": "14",
        "Andorre": "112",
        "Angola": "112",
        "Argentine": "911",
        "Arménie": "112",
        "Australie": "000",
        "Autriche": "112",
        "Azerbaïdjan": "112",
        "Bahamas": "911",
        "Bahreïn": "999",
        "Bangladesh": "999",
        "Barbade": "211",
        "Bélarus": "112",
        "Belgique": "112",
        "Belize": "911",
        "Bénin": "112",
        "Bhoutan": "112",
        "Bolivie": "110",
        "Bosnie-Herzégovine": "112",
        "Botswana": "999",
        "Brésil": "190",
        "Brunei": "991",
        "Bulgarie": "112",
        "Burkina Faso": "112",
        "Burundi": "112",
        "Cambodge": "117",
        "Cameroun": "112",
        "Canada": "911",
        "Cap-Vert": "132",
        "République centrafricaine": "112",
        "Tchad": "112",
        "Chili": "133",
        "Chine": "110",
        "Colombie": "123",
        "Comores": "112",
        "République du Congo": "112",
        "République démocratique du Congo": "112",
        "Costa Rica": "911",
        "Croatie": "112",
        "Cuba": "106",
        "Chypre": "112",
        "République tchèque": "112",
        "Danemark": "112",
        "Djibouti": "112",
        "Dominique": "999",
        "République dominicaine": "911",
        "Équateur": "911",
        "Égypte": "122",
        "El Salvador": "911",
        "Guinée équatoriale": "112",
        "Érythrée": "112",
        "Estonie": "112",
        "Eswatini": "999",
        "Éthiopie": "911",
        "Fidji": "911",
        "Finlande": "112",
        "France": "112",
        "Gabon": "112",
        "Gambie": "112",
        "Géorgie": "112",
        "Allemagne": "112",
        "Ghana": "999",
        "Grèce": "112",
        "Grenade": "911",
        "Guatemala": "110",
        "Guinée": "112",
        "Guinée-Bissau": "112",
        "Guyana": "911",
        "Haïti": "114",
        "Honduras": "911",
        "Hongrie": "112",
        "Islande": "112",
        "Inde": "112",
        "Indonésie": "112",
        "Iran": "110",
        "Irak": "112",
        "Irlande": "112",
        "Israël": "100",
        "Italie": "112",
        "Côte d'Ivoire": "112",
        "Jamaïque": "119",
        "Japon": "110",
        "Jordanie": "911",
        "Kazakhstan": "112",
        "Kenya": "999",
        "Kiribati": "999",
        "Koweït": "112",
        "Kirghizistan": "112",
        "Laos": "1190",
        "Lettonie": "112",
        "Liban": "112",
        "Lesotho": "112",
        "Libéria": "911",
        "Libye": "112",
        "Liechtenstein": "112",
        "Lituanie": "112",
        "Luxembourg": "112",
        "Madagascar": "117",
        "Malawi": "997",
        "Malaisie": "999",
        "Maldives": "119",
        "Mali": "112",
        "Malte": "112",
        "Îles Marshall": "911",
        "Mauritanie": "112",
        "Maurice": "112",
        "Mexique": "911",
        "Micronésie": "911",
        "Moldavie": "112",
        "Monaco": "112",
        "Mongolie": "105",
        "Monténégro": "112",
        "Maroc": "112",
        "Mozambique": "112",
        "Myanmar": "199",
        "Namibie": "112",
        "Nauru": "112",
        "Népal": "100",
        "Pays-Bas": "112",
        "Nouvelle-Zélande": "111",
        "Nicaragua": "118",
        "Niger": "112",
        "Nigeria": "112",
        "Macédoine du Nord": "112",
        "Norvège": "112",
        "Oman": "9999",
        "Pakistan": "15",
        "Palaos": "911",
        "Palestine": "100",
        "Panama": "911",
        "Papouasie-Nouvelle-Guinée": "000",
        "Paraguay": "911",
        "Pérou": "105",
        "Philippines": "911",
        "Pologne": "112",
        "Portugal": "112",
        "Qatar": "999",
        "Roumanie": "112",
        "Russie": "112",
        "Rwanda": "112",
        "Saint-Kitts-et-Nevis": "911",
        "Sainte-Lucie": "999",
        "Saint-Vincent-et-les-Grenadines": "911",
        "Samoa": "999",
        "Saint-Marin": "112",
        "Arabie saoudite": "999",
        "Sénégal": "112",
        "Serbie": "112",
        "Seychelles": "999",
        "Sierra Leone": "112",
        "Singapour": "999",
        "Slovaquie": "112",
        "Slovénie": "112",
        "Îles Salomon": "999",
        "Somalie": "112",
        "Afrique du Sud": "10111",
        "Corée du Sud": "112",
        "Soudan du Sud": "112",
        "Espagne": "112",
        "Sri Lanka": "119",
        "Soudan": "112",
        "Suriname": "112",
        "Suède": "112",
        "Suisse": "112",
        "Syrie": "112",
        "Taïwan": "110",
        "Tadjikistan": "112",
        "Tanzanie": "112",
        "Thaïlande": "191",
        "Timor oriental": "112",
        "Togo": "112",
        "Tonga": "911",
        "Trinité-et-Tobago": "999",
        "Tunisie": "197",
        "Turquie": "112",
        "Turkménistan": "112",
        "Tuvalu": "911",
        "Ouganda": "112",
        "Ukraine": "112",
        "Émirats arabes unis": "999",
        "Royaume-Uni": "112",
        "États-Unis": "911",
        "Uruguay": "911",
        "Ouzbékistan": "112",
        "Vanuatu": "112",
        "Vatican": "112",
        "Venezuela": "171",
        "Vietnam": "113",
        "Yémen": "112",
        "Zambie": "112",
        "Zimbabwe": "999"
    }

    return Object.entries(data).map(([key, value]) => ({
        label: key,
        value: value,
    }));
}

export {list}