import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import {Accordion} from "react-bootstrap";
import logo from "../assets/picto.png";

const Notice = () => {
    const {t} = useTranslation();
    return (
        <div className='container col-12 col-md-4 ps-5 pe-5 ms-auto me-auto h-100 roboto-regular'>
            <h3 className='mt-3 mb-5 roboto-bold dark-blue'>Guide pratique</h3>
            <Accordion>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><span className='text-danger'>
                        <strong className='roboto-bold'>Très important</strong>
                    </span></Accordion.Header>
                    <Accordion.Body>
                        <span className='text-danger'>
                            URGENT Assistance est une application d'<strong>appel au secours</strong>.<br/>
                            Donc, <strong>active</strong> URGENT assistance par précaution <strong>avant</strong> toute situation
                            potentiellement à risque.<br/>
                            Pour cela, active l’application et <strong>garde-la
                            aﬃchée en premier plan</strong> avec l’écran d’accueil
                            que tu as choisi.<br/>
                            Lis attentivement les informations de ce
                            guide pratique pour tirer partie pleinement de
                            ces fonctionnalités pratiques.<br/>
                            Lire la Présentation
                        </span>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><span className='roboto-bold'>Présentation</span></Accordion.Header>
                    <Accordion.Body>
                        Urgent Assistance permet de lancer une
                        demande de secours vers 1 à 3 contacts :
                        <ul>
                            <li>
                                en SMS avec géolocalisation GPS suivie si tu
                                te déplaces
                            </li>
                            <li>
                                par un appel vocal si nécessaire vers le 1er
                                contact d’urgence.
                            </li>
                        </ul>
                        Lire section Lancer une alarme.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header><span className='roboto-bold'>Lancer une alarme</span></Accordion.Header>
                    <Accordion.Body>
                        <strong>Un double clic sur l'icone : </strong> <img
                        src={logo}
                        className="img-fluid"
                        style={{
                            width: '5%'
                        }}
                        alt=''/>
                        <ul className='list-unstyled'>
                            <li>
                                1. pour envoyer un SMS vers tes contacts
                                d’urgence
                            </li>
                            <li>
                                2. puis en option, pour lancer un appel vocal
                                pour parler à ton contact n°1.
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><span
                        className='roboto-bold'>Géolocalisation GPS temps réel</span></Accordion.Header>
                    <Accordion.Body>
                        Voici le modèle de SMS envoyé :<br/>
                        <strong>SOS de NOM PRENOM</strong><br/>
                        <strong>tel :</strong>+## # ## ## ## ##<br/>
                        localisé https://www.google.com/maps?<br/>
                        <strong>
                            Clique sur :
                            <ul>
                                <li>
                                    le n° de téléphone pour rappeler directement
                                    ton correspondant
                                </li>
                                <li>
                                    l’hyperlien pour afficher sa géolocalisation GPS
                                </li>
                            </ul>
                        </strong>
                        <span className='text-danger'>
                            Important
                        </span>
                        <p>
                            Par prudence, tu peux autoriser l’application à
                            envoyer le suivi de ta position à tes contacts
                            en cas de harcèlement ou enlèvement..
                            Si non autorisé, tes contacts recevront ta
                            géolocalisation GPS instantanée.<br/>
                            Autorisation dans la section Mon Compte
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <span className='roboto-bold'>Solde d'alarme</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        Le solde d’alarme est actualisé à l’heure et au
                        jour près. Arrivé à 1, tu recevras un email pour
                        recharger ton compte.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <span className='roboto-bold'>Fonds d'écran</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        Les fonds d’écran sont destinés à une
                        utilisation discrète de l’application dans une
                        situation à risque.<br/>
                        Tu peux choisir ton fonds d’écran dans notre
                        gamme comprenant plusieurs thèmes ou
                        même télécharger une photo ou image de ton
                        choix.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <span className='roboto-bold'>Contacts d’urgence</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        Les contacts d’urgence sont choisis librement
                        parmi les personnes de confiance dans ton
                        entourage proche : famille, amis, voisins qui
                        peuvent venir de te porter secours ou appeler
                        les services d’urgence ou la police.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className='fixed-bottom bottom-0 bg-white text-center px-5 pt-3' style={
                {
                    height: '75px'
                }
            }>
                <Link to='/' className="btn btn-outline-dark outline-grey shadow-lg w-100">{t('buttons.close')}</Link>
            </div>
        </div>
    )
}

export default Notice;