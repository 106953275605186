import logo from "../assets/logo-blanc-1.png";
import React, {useState} from "react";
import CorrespondentBlock from "./CorrespondentBlock";
import {toast} from "react-toastify";
import {isValidPhoneNumber} from "libphonenumber-js";
import {Contact} from "../models/Contact";
import {Link, useNavigate} from "react-router-dom";
import {validateEmail} from "../helpers/utilities";
import {Trans, useTranslation} from "react-i18next";

export default function CreateCorrespondents({setSteps, first = true}: { setSteps: any, first: boolean }) {

    const {t} = useTranslation();
    const getContactsFromLocalStorage = (): Contact[] | null => {
        const savedContacts = localStorage.getItem('correspondents');
        if (savedContacts) {
            return JSON.parse(savedContacts) as Contact[];
        }
        return null;
    };
    const [contacts, setContacts] = useState<Contact[]>(() => {
        return getContactsFromLocalStorage() || [
            {
                name: '',
                number: '',
                email: '',
                relation: '',
            },
        ]
    });
    const navigate = useNavigate();

    const validateCorrespondents = () => {
        let save = true
        contacts.forEach((value: any, index) => {
            if (value.number.length > 5) {
                if (value.name === '') {
                    toast.error(`Le nom du contact Nº${index + 1} est vide`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    save = false;
                    return;
                }

                if (value.email === '') {
                    toast.error(`L'email du contact Nº${index + 1} est vide`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    save = false;
                    return;
                } else {
                    if (!validateEmail(value.email)) {
                        toast.error(`L'email du contact Nº${index + 1} est invalide`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        save = false;
                        return;
                    }
                }
            }

            if (value.number.length > 5 && !isValidPhoneNumber(value.number as string)) {
                toast.error(`Le numéro du contact Nº${index + 1} est invalide`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                save = false
                return;
            }
        });

        if (save) {
            localStorage.setItem('correspondents', JSON.stringify(contacts));
            const user = JSON.parse(localStorage.getItem('user') || '[]');
            const user_key = localStorage.getItem('user_key');
            if (Object.keys(user).length > 0) {
                contacts.forEach((v: any, index) => {
                    if (v.number.length > 5) {
                        const request = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                email: v.email,
                                user_id: user_key,
                                name: v.name,
                                index: index
                            })
                        };
                        fetch(`${process.env.REACT_APP_API_URL}/emails/correspondents`, request)
                    }
                })
            }

            localStorage.setItem('first_launch', 'no');
            navigate('/');
        }
    }

    const addContact = () => {
        let tmp = [...contacts];
        tmp.push({
            name: '',
            number: '',
            email: '',
            relation: '',
        });
        setContacts(tmp);
    }

    return (
        <div className={`container roboto-regular ps-5 pe-5 ${first ? 'p-4' : ''} col-12 col-md-4 ms-auto me-auto`}>
            <div className='text-center'>
                <img
                    src={logo}
                    className="img-fluid mt-5 mb-3"
                    style={{
                        width: '45%'
                    }} alt=''/>
            </div>
            <div className='text-center mb-5 mt-5'>
                <h3 className='dark-blue roboto-bold'>
                    <Trans i18nKey={'form-elements.assign-contact'}></Trans>
                </h3>
                <h6 className='color-grey roboto-regular'>
                    <Trans i18nKey={'form-elements.informational-email'}></Trans>
                </h6>
            </div>
            {contacts.map((v: object, i: number) => (
                <CorrespondentBlock number={i + 1} index={i} setContacts={setContacts} contacts={contacts} key={i}/>
            ))}
            <div className='mb-5'>
                {contacts.length < 3 &&
                    <div className='d-flex justify-content-center mt-5'>
                        <button className='btn btn-outline-dark w-100 outline-grey dark-blue' onClick={addContact}>
                            {t('buttons.add-correspondent')}
                        </button>
                    </div>}
            </div>
            <div style={{
                height: '75px'
            }}>

            </div>
            {first && <div className='fixed-bottom bottom-0 bg-white text-center px-5 pt-3' style={
                {
                    height: '75px'
                }
            }>
                <button className='btn btn-primary bg-dark-blue text-white w-100 dark-blue-border shadow-sm'
                        onClick={validateCorrespondents}
                >
                    {t('buttons.validate')}
                </button>
            </div>}
            {!first &&
                <div className='fixed-bottom bottom-0 bg-white text-center px-5 pt-3' style={
                    {
                        height: '75px'
                    }
                }>
                    <div className='row'>
                        <div className='col-6'>
                            <Link to='/' className='btn btn-outline-dark outline-grey w-100'>Fermer</Link>
                        </div>
                        <div className='col-6'>
                            <button className='btn btn-primary bg-dark-blue text-white w-100 dark-blue-border shadow-sm'
                                    onClick={validateCorrespondents}
                            >
                                {t('buttons.validate')}
                            </button>
                        </div>
                    </div>
                </div>}
        </div>
    )
}